import {
    format,
    add,
} from 'date-fns';

function dateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

export {
    format,
    add,
    dateToUTC,
};