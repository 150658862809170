export function flatten(args) {
    return args.reduce((acc, val) => acc.concat(val), []);
};

export function URLJoin(args) {
    return args
        .join('/')
        .replace(/\/+/g, '/')
        .replace(/\/$/, '')
        .replace(/^(.+):\//, '$1://')
        .replace(/^file:/, 'file:/')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?')
    ;
};

export function toFormData(attributes) {
    const formData = new FormData();

    Object.keys(attributes).forEach(key => {
        if (attributes[key] instanceof Array) {
            for (let value of attributes[key]) {
                formData.append(`${key}[]`, value);
            }

            return;
        }

        return formData.append(key, attributes[key]);
    });

    return formData;
}