import Endpoint from '../Endpoint.js'
import FaceId from './FaceId.js'

class EmployeesById extends Endpoint {
	buildUrl({ param }) {
		return ['employees', param]
	}

	// async afterRequest(response, request) {
	// 	const { data: faces } = await FaceId.paginate({
	// 		employeeId: response.data.data.id,
	// 	})

	// 	response.data.data = {
	// 		...response.data.data,
	// 		faces,
	// 	}

	// 	console.log('employees response data: ', response.data.data)
	// 	return super.afterRequest(response, request)
	// }
}

export default new EmployeesById()
