import Endpoint from '../Endpoint.js';
import { state } from '@/store';

class EmployeeCheck extends Endpoint {
    buildUrl ({ action }) {
		return ['devices', state.device, action];
	}

    in(form, config = {}) {
        return this.request({ 
            method: 'POST', 
            action: 'check-in', 
            data: form,
            ...config 
        });
    }

    out(form, config = {}) {
        return this.request({ 
            method: 'POST', 
            action: 'check-out', 
            data: form,
            ...config 
        });
    }
};

export default new EmployeeCheck;