<template>
	<div class="inline-block">
		<slot v-bind="slotProps"/>
	</div>
</template>

<script>
import { computed } from '@vue/runtime-core';
	export default {
		name: 'Keyboard',
		props: {
			modelValue: {
				required: true
			},
			type: {
				type: String,
				default: 'numeric',
				validator(value) {
					return ['numeric', 'alphabetic', 'custom'].includes(value);
				}
			},
			customKeys: {
				type: Array,
				default: () => []
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		emits:['update:modelValue'],
		setup(props, {emit}) {

			const select = (key) => {
				if(!props.disabled){
					emit('update:modelValue', props.modelValue + key)
				}
			}
			
			const backspace = () => emit('update:modelValue', props.modelValue.slice(0, -1));

			const clear = () => emit('update:modelValue', '');

			const keys = computed(() => {
				switch(props.type) {
					case 'numeric':
						return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
					case 'alphabetic':
						return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'del', 'Y', 'Z', 'space'];
					default:
						return props.customKeys;
				}
			});

			const slotProps = computed(() => {
				return {
					keys: props.customKeys,
					select: select,
					backspace: backspace,
					clear: clear
				}
			});


			return {
				keys,
				slotProps,
				select,
				backspace,
				clear
			}
		}
		// computed: {
		// 	keys() {
		// 		switch(this.type) {
		// 			case 'numeric':
		// 				return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
		// 			case 'alphabetic':
		// 				return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'del', 'Y', 'Z', 'space'];
		// 			default:
		// 				return this.customKeys;
		// 		}
		// 	},
		// 	slotProps() {
		// 		return {
		// 			keys: this.keys,
		// 			select: this.select,
		// 			backspace: this.backspace,
		// 			clear: this.clear,
		// 		};
		// 	}
		// },
		// methods: {
		// 	select(key) {
		// 		console.log('input: ', this.modelValue + key)
		// 		if (!this.disabled) {
		// 			this.$emit('input', this.modelValue + key);
		// 		}
		// 	},
		// 	backspace() {
		// 		this.$emit('input', this.modelValue.slice(0, -1));
		// 	},
		// 	clear() {
		// 		this.$emit('input', '');
		// 	}
		// }
	};
</script>
