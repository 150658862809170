import Endpoint from '../Endpoint.js';

class Faces extends Endpoint {
	buildUrl() {
		return ['documents', 'face-id']
	}

	paginate(form, config = {}) {
		return this.request({
			method: 'POST',
			action: 'paginate',
			data: form,
			...config,
		})
	}
}

export default new Faces()
