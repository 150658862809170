export const readURL = (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = e => res(e.target.result);
        reader.onerror = e => rej(e);

        reader.readAsDataURL(file);
    });
};

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

export async function asyncMap(array, callback) {
    return await Promise.all(array.map(async function (item, index, arr) {
        return await callback(item, index, arr);
    }));
};