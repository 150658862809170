import Endpoint from '../Endpoint.js'
import resolver from '../services/resolver.js'

import FaceId from '@/api/endpoints/FaceId.js'

class EmployeesByCompany extends Endpoint {
	buildUrl() {
		return ['employees-by-company-ids']
	}

	async afterRequest(response, request) {
		response.data.data = await resolver(response.data.data, 'id', 'faces', async id => {
			return await FaceId.paginate({ employeeId: id })
		})

		return super.afterRequest(response, request)
	}

	paginate(form, config = {}) {
		return this.request({
			method: 'POST',
			action: 'paginate',
			data: form,
			...config
		})
	}
}

export default new EmployeesByCompany()
