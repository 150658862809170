import Endpoint from '../Endpoint.js';

class Document extends Endpoint {
    buildUrl ({ param }) {
		return [`documents`, param];
    }
    
    getUrl(attributes) {
        return  this.request({
            method: 'GET',
            url: `documents/${this.primaryKey(attributes)}/url`
        });
    }
};

export default new Document;