<template>
    <div class="">
        <slot 
            :analysis="analysis"
        />
    </div>
</template>

<script>
    import { ref, watch } from 'vue';

    export default {
        name: 'AudioAnalyzer',
        props: {
            source: {
                type: null,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        emits: ['audio:analyzed'],
        setup(props, { emit }) {
            let audio;
            let context;
            let analyzer;

            const analysis = ref({});

            function initialize(source) {
                if (!props.source) {
                    return;
                }

                context = new AudioContext();
                analyzer = context.createAnalyser();
                analyzer.fftSize = 2048;

                audio = context.createMediaStreamSource(source);
                
                audio.connect(analyzer);
                // analyzer.connect(context.destination);

                analyze();
            }

            initialize(props.source);

            watch(() => props.source, (source) => {
                if (source) {
                    initialize(source);
                }
            });

            // audio analysis
            
            function analyze() {
                if (!props.active || !analyzer) {
                    return;
                }

                const bufferLength = analyzer.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                analyzer.getByteTimeDomainData(dataArray);

                let average = 0;
                let max = 0;

                for (let i = 0; i < dataArray.length; i++) {
                    const a = Math.abs(dataArray[i] - 128);
                    average += a;
                    max = Math.max(max, a);
                }

                if (analysis.value) {
                    analysis.value = {
                        level: average / dataArray.length
                    };

                    emit('audio:analyzed', analysis.value);
                }


                // emit('audio:analyzed', analysis.value);
                requestAnimationFrame(analyze);
            }

            watch(() => props.active, async (active) => {
                if (active) {
                    analyze();
                }
            });


            return {
                analysis
            };
        }

    }
</script>

<style>

</style>