<template>
	<div class="absolute inset-0 w-full h-full">
		<canvas ref="canvas" class="w-full h-full flip-h" />
	</div>
</template>

<script>
	import useEmployeeStore from '@/stores/useEmployeeStore';

	import { ref, watchEffect } from 'vue';
	import * as faceapi from 'face-api.js';


	export default {
		name: 'FaceDetection',
		props: {
			source: {
				type: null,
				required: true
			},
			dimensions: {
				type: Object,
				required: true,
				validator(value) {
					return value.hasOwnProperty('width') && value.hasOwnProperty('height')
				}
			},
			active: {
				type: Boolean,
				default: false
			}
		},
		emits: ['faces:match', 'faces:unknown'],
		setup(props, { emit }) {
			// database
			const {
				database,
				databaseDescriptors,
			} = useEmployeeStore();

			// detection
			const canvas = ref()
			const options = new faceapi.SsdMobilenetv1Options({
				// inputSize: 128,
				// scoreThreshold: 0.5,
				minConfidence: 0.3,
			})

			async function detect() {
				if (!props.source) {
					return
				}

				// console.log('detect');
				
				const descriptor = await faceapi
					.detectSingleFace(props.source, options)
					.withFaceLandmarks()
					.withFaceDescriptor()

				findFaceMatches([descriptor], props.dimensions);

				if (props.active) {
					setTimeout(() => detect(), 50);
				}
			}

			// face matches
			function findFaceMatches(descriptors, dimensions) {
				if (databaseDescriptors.value.length === 0) {
					emit('faces:unknown')
					return
				}

				if (descriptors.length === 0 || !descriptors[0]) {
					emit('faces:match', [])
					return
				}

				try {
					const maxDescriptorDistance = 0.5
					const faceMatcher = new faceapi.FaceMatcher(
						databaseDescriptors.value,
						maxDescriptorDistance
					)

					const faces = descriptors.map(({ descriptor, detection }) => {
						const { label } = faceMatcher.findBestMatch(descriptor)
						const entry = database.value.find(e => e.id === label) || {}

						const box = detection.box
						const circle = {
							radius: Math.sqrt(Math.pow(box.width / 2, 2) + Math.pow(box.height / 2, 2)),
							center: {
								x: dimensions.width - (box.x + box.width / 2),
								y: box.y + box.height / 2
							}
						}

						return {
							...entry,
							id: label,
							detection: { box, circle }
						}
					})

					if (faces.length > 0 && faces.find(face => face.id === 'unknown')) {
						emit('faces:unknown')
						return
					}

					emit('faces:match', faces)
				} catch (error) {
					console.log(error)
					// throw new Error('Error when finding face matches.');
				}
			}

			// activation
			watchEffect(() => {
				if (props.active) {
					detect();
				}
			})

			return {
				// detection
				canvas
			}
		}
	}
</script>
