<template>
	<video
		v-show="active"
		ref="video"
		:srcObject="stream"
		autoplay
		playsinline
		muted
		@loadeddata="onStreamReady"
		@play="onStreamPlay"
	/>
</template>

<script>
	import { ref, onMounted, onBeforeUnmount, watchEffect } from 'vue'

	export default {
		name: 'StreamVideo',
		props: {
			constraints: {
				type: Object,
				default: () => ({
					video: true,
					audio: true,
				}),
			},
			active: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['stream:ready', 'stream:play'],
		setup(props, { emit }) {
			// video
			const video = ref()

			// stream
			const stream = ref()
			async function openStream() {
				try {
					stream.value = await navigator.mediaDevices.getUserMedia({
						...props.constraints,
					})
				} catch (error) {
					console.log('An error courred while open the camera stream.')
				}
			}

			function closeStream() {
				if (!stream.value) {
					return
				}

				canPlayFeedback.value = false
				stream.value.getTracks().forEach(track => {
					track.stop()
				})
			}

			// stream ready
			const canPlayFeedback = ref(false)
			function onStreamReady() {
				canPlayFeedback.value = true

				emit('stream:ready', {
					video: video.value,
					stream: stream.value,
					dimensions: getDimensions(),
				})
			}

			function onStreamPlay() {
				emit('stream:play')
			}

			function getDimensions() {
				return { width: video.value.videoWidth, height: video.value.videoHeight }
			}

			onMounted(() => openStream())
			onBeforeUnmount(() => closeStream())

			return {
				video,
				stream,
				canPlayFeedback,

				onStreamReady,
				onStreamPlay,
			}
		},
	}
</script>
