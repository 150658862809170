import { useRoute } from 'vue-router';
import { actions } from '@/store';

export default function useUrlParams(fn) {
    const route = useRoute();
    const { 
        token = '', 
        device = '', 
        companies = '' 
    } = route.query;

    actions.setToken(token);
    actions.setDevice(device);
    actions.setCompanies(companies.split(','));
};
