import Endpoint from '../Endpoint.js'

import resolver from '../services/resolver'
import Document from './Document.js'

import { toFormData } from '../utils.js'
import { actions } from '@/store'

import * as faceapi from 'face-api.js'

class FaceId extends Endpoint {
	buildUrl({ employeeId }) {
		return ['face-ids', employeeId, 'documents']
	}

	sendDocument(data, employeeId) {
		const formData = toFormData(data)

		return this.request({
			method: 'POST',
			url: `face-ids/${employeeId}/documents`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}

	async afterRequest(response, request) {
		const options = new faceapi.SsdMobilenetv1Options({
			minConfidence: 0.3,
			maxResults: 1,
		})

		if (['paginate', 'all'].includes(request.action)) {
			response.data.data = await resolver(response.data.data, 'id', 'descriptor', async id => {
				const { url } = await Document.getUrl({ id })
				const image = await faceapi.fetchImage(url)

				const faceDescriptor = await faceapi
					.detectSingleFace(image, options)
					.withFaceLandmarks()
					.withFaceDescriptor()

				// if (!Boolean(faceDescriptor)) {
				// 	actions.setReload(true)
				// }

				console.log('employee: ', request.employeeId, ' - face?: ', Boolean(faceDescriptor))
				return { data: faceDescriptor ? faceDescriptor.descriptor : null }
			})

			response.data.data = response.data.data.filter(item => Boolean(item.descriptor))
		}

		return super.afterRequest(response, request)
	}
}

export default new FaceId()
