import Endpoint from '../Endpoint.js';

class Socket extends Endpoint {
    buildUrl ({ param }) {
		return [`socket/token`, param];
    }
    
    connect() {
        return this.request({
            action: 'connect',
            method: 'POST',
        });
    }
};

export default new Socket;