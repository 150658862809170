import { reactive, computed, toRefs } from 'vue';
import { toLocal } from '@/store/storages';
import { state as globalState } from '@/store';

import Faces from '@/api/endpoints/Faces';
import EmployeesById from '@/api/endpoints/EmployeesById';
import * as faceapi from 'face-api.js'

import useSocket from '@/composables/useSocket';
import { asyncMap } from '@/utils/helper';
import { format, dateToUTC } from '@/utils/dates';


const store = reactive({
    employees: [],
    loading: false,
    lastFetched: null,
    loaded: 0,
    total: 0,
});

// const { socket } = useSocket();


export default function useFacesStore() {
    // state
    const state = reactive(store);

    toLocal(state, {
        attrs: ['employees', 'lastFetched']
    });

    // face recognition models
    function getFaceModels() {
        const MODELS_URL = process.env.VUE_APP_MODELS_URL;

        return Promise.all([
            faceapi.loadTinyFaceDetectorModel(MODELS_URL),
            faceapi.loadFaceLandmarkTinyModel(MODELS_URL),
            faceapi.loadSsdMobilenetv1Model(MODELS_URL),
            faceapi.loadFaceLandmarkModel(MODELS_URL),
            faceapi.loadFaceRecognitionModel(MODELS_URL)
        ]);
    }

    // database
    const database = computed(() => {
        const employees = state.employees.map(employee => {
            const faceDescriptors = descriptorsToFloatArrays(employee.faceDescriptors);
            
            return {
                ...employee,
                faceDescriptors,
                descriptors: labelFaceDescriptors(employee.id, faceDescriptors)
            };
        });

        return employees.filter(({ faceDescriptors }) => {
            return faceDescriptors.length > 0;
        });
    });

    const databaseDescriptors = computed(() => database.value.map(i => i && i.descriptors))

    function descriptorsToFloatArrays(descriptors) {
        return descriptors.map(descriptor => {
            const array = new Float32Array(Object.keys(descriptor).length);
            for (const key in descriptor) {
                array[key] = descriptor[key];
            }

            return array;
        });
    }

    function labelFaceDescriptors(label, descriptors) {
        return new faceapi.LabeledFaceDescriptors(label, descriptors);
    }

    // employees
    async function getAllEmployees() {
        let currentPage = 1
        let pages;

        try {
            state.loading = true;
            state.loaded = 0;

            // socket.io.off('DocumentFaceIdCreated', onDocumentFaceIdCreated);
            // socket.io.off('DocumentFaceIdDeleted', onDocumentFaceIdDeleted);

            await getFaceModels();

            do {
                const { data, meta } = await getEmployees(currentPage);
    
                currentPage++;
                pages = meta.lastPage;
                state.total = meta.total;

                const employees = await transformEmployees(data);
                for (const employee of employees) {
                    addEmployee(employee);
                }
            } while (currentPage <= pages);

            state.lastFetched = format(dateToUTC(new Date), 'yyyy-MM-dd HH:mm:ss');
        } catch (error) {
            console.error('An error ocurred while getting employees: ', error);
        } finally {
            state.loading = false;

            // socket.io.on('DocumentFaceIdCreated', onDocumentFaceIdCreated);
            // socket.io.on('DocumentFaceIdDeleted', onDocumentFaceIdDeleted);
        };
    }

    function getEmployees(page=1) {
        return Faces.paginate({ 
            companyIds: globalState.companies,
            startTime: state.lastFetched,
            page,
        });
    }

    async function transformEmployee({ id, fullName, pin, code, createdFaceIds, deletedFaceIds }) {
        const faces = await getFacesFromEmployee(createdFaceIds);
        const faceDescriptors = await getDescriptorsFromImages(faces);

        state.loaded++;
        
        return {
            id,
            name: fullName,
            createdFaceIds,
            deletedFaceIds,
            pin,
            code,
            faceDescriptors,
        };
    }

    async function transformEmployees(data) {
        const employees = await asyncMap(data, async (employee) => {
            return await transformEmployee(employee);
        });

        return employees;

        // return employees.filter(({ faceDescriptors }) => {
        //     return faceDescriptors.length > 0;
        // });
    }

    async function getFacesFromEmployee(urls) {
        const images = await asyncMap(urls, async (url) => {
            try {
                return await faceapi.fetchImage(url);
            } catch (error) {
                console.error('An error ocurred while fetching image: ', error);
                return false;
            }
        });

        return images.filter(image => !!image);
    }

    async function getDescriptorsFromImages(images) {
        const descriptors = await asyncMap(images, async (image) => {
            try {
                const options = new faceapi.SsdMobilenetv1Options({
                    // scoreThreshold: 0.5,
                    minConfidence: 0.3,
                });
        
                return faceapi
                    .detectSingleFace(image, options)
                    .withFaceLandmarks()
                    .withFaceDescriptor()
                ;
            } catch (error) {
                console.error('An error ocurred while fetching image: ', error);
                return false;
            }
        });

        return descriptors
            .filter(descriptor => !!descriptor)
            .map(({ descriptor }) => descriptor)
        ;
    }


    async function addEmployee(employee) {
        console.log('addign employee: ', employee);
        const index = state.employees.findIndex(e => e.id == employee.id);
        if (index >= 0) {
            mergeEmployee(employee, index);
        } else {
            createEmployee(employee);
        }
    }

    function createEmployee(employee) {
        const data = {
            ...employee,
            faces: employee.createdFaceIds,
            createdFaceIds: undefined,
            deletedFaceIds: undefined,
        };

        state.employees = [ ...state.employees, data ];
        console.log('creating employee [name]: ', employee.name, employee.id, data);
    }

    function mergeEmployee(employee, index) {
        let faces = state.employees[index].faces;
        let faceDescriptors = state.employees[index].faceDescriptors;

        // deletedFaceId
        if(employee.deletedFaceIds.length > 0) {
            console.log('employee previous faces: ', state.employees[index].faces, employee.deletedFaceIds);
        
            for (const deletedFace of employee.deletedFaceIds) {
                const faceIndex = state.employees[index].faces?.findIndex(url => {
                    return parseFaceUrl(deletedFace) === parseFaceUrl(url);
                });

                console.log('found index: ', faceIndex);
                if (faceIndex >= 0) {
                    faces.splice(faceIndex, 1);
                    faceDescriptors.splice(faceIndex, 1);
                }
            }
        }

        const update = {
            ...state.employees[index],
            faces: [
                ...faces,
                ...employee.createdFaceIds,
            ],
            faceDescriptors: [
                ...faceDescriptors, 
                ...employee.faceDescriptors 
            ],
        };

        console.log('updating employee [name]: ', employee.name, employee.id, update);
        state.employees = [ ...state.employees.slice(0, index), update, ...state.employees.slice(index+1) ];
    }

    async function onDocumentFaceIdCreated(payload) {
        const { employeeId, url } = JSON.parse(payload);
        console.log('faceID updated: ', employeeId, url);

        const index = state.employees.findIndex(e => e.id === employeeId);
        const exists = index >= 0;
        let employee;

        if (!exists) {
            const entry = await EmployeesById.find(employeeId);
            employee = {
                ...entry,
                fullName: `${entry.firstName} ${entry.lastName}`,
                createdFaceIds: [url]
            };
        } else {
            employee = {
                ...state.employees[index],
                createdFaceIds: [
                    ...state.employees[index].createdFaceIds,
                    url,
                ]
            };
        }
        
        const transform = await transformEmployee(employee);
        addEmployee(transform);
    }

    async function onDocumentFaceIdDeleted(payload) {
        const { employeeId, url } = JSON.parse(payload);
        console.log('faceID deleted: ', employeeId, url);
    }

    function parseFaceUrl(url) {
        const result = url.match(new RegExp("(.*)" + '/private'));
        return result[1];
    // return result[1]
    //     return url.substring(0, url.indexOf("?"));
    }


    return {
        ...toRefs(state),
        database,
        databaseDescriptors,
        getAllEmployees,
    };
}