import Crud from "./Crud";

import { notify } from '@/components/notifications';
import { state, actions } from '@/store';

import axios from 'axios';

class Endpoint extends Crud {
    baseUrl() {
        return process.env.VUE_APP_API_URL;
    }

    buildUrl(request) {
        return [];
    }

    makeRequest(request) {
        const { method, url, data, headers, query } = request;

        return axios({
            method,
            url,
            data,
            headers,
            params: query,
            // withCredentials: false
        });
    }

    beforeRequest(request) {
        request.headers = { 
            ...request.headers, 
            "Authorization": `Bearer ${state.token}`,
    };
        return request;
    }

    afterRequest(response, request) {
        if (['paginate', 'login'].includes(request.action)) {
            return response.data;
        }

        return response.data.data;
    }

    failedRequest(error) {
        const { status } = error.response;

        // Error 401: Unauthorized
        if (status === 401) {
            console.log('#Error 401: Unauthorized');
            // actions.resetAuth();
            
            notify({
                title: 'No autorizado',
                text: 'Por favor, vuelve a registrar la tablet.'
            });
        }

        // Error 402: Payment required
        // if (status === 402) {
        //     console.log('#Error 402: Payment Required');
        // }

        // Error 403: Forbidden
        if (status === 403) {
            console.log('#Error 403: Forbidden');

            notify({
                title: 'Prohibido',
                text: 'No tienes permiso para obtener estos datos.'
            });
        }

        // Error 404: Not found
        if (status === 404) {
            console.log('#Error 404: Not found');
        }

        // Error 419: Token mismatch
        if (status === 419) {
            console.log('#Error 419: Authentication Timeout');
        }

        // Error 422: Unprocessable entity
        if (status === 422) {
            const { data: { message } } = error.response;
            console.log('#Error 422: Unprocessable entity');

            notify({
                title: 'Formulario no procesable',
                text: 'Los datos introducidos no son correctos.'
            });
        }

        // Error 500: Server error
        if (status >= 500) {
            console.log('#Error 500: Server error');

            notify({
                title: 'Error de servidor',
                text: 'Ha ocurrido un error inesperado.'
            });
        }

        return Promise.reject(error);
    }
};

export default Endpoint;