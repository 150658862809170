import Endpoint from "../Endpoint.js";
import { state } from "@/store";

class Employees extends Endpoint {
    buildUrl() {
        return ["devices", state.device, "employee"];
    }

    find(form, config = {}) {
        return this.request({
            method: "POST",
            action: "find",
            data: form,
            ...config,
        });
    }
}

export default new Employees();
