<template>
    <div class="relative flex items-center">
        <input 
            type="range" 
            :value="modelValue" 
            :min="min"
            :max="max"
            @input="input"
        />

        <div 
            class="absolute top-0 h-4 bg-red-500 rounded-full pointer-events-none"
            :style="{ width: width + '%' }"
        ></div>
    </div>
</template>

<script>
    import { computed } from 'vue';

    export default {
        name: 'InputRange',
        props: {
            modelValue: {
                type: Number,
                default: 0
                // required: true
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            }
        },
        emits: ['update:modelValue'],
        setup(props, { emit }) {
            function input(event) {
                const value = event.target.value;
                emit('update:modelValue', parseFloat(value));
            }

            const width = computed(() => {
                const value = Math.min(Math.max(props.modelValue, props.min), props.max);
                return 100 * (value - props.min) / (props.max - props.min);
            });

            const left = computed(() => {
                const value = Math.min(Math.max(props.modelValue, props.min), props.max);
                return 100 * (value - props.min) / (props.max - props.min);
            });

            return {
                input,
                width,
                left
            };
        }
    }
</script>

<style>
    input[type='range'] {
        @apply appearance-none w-full bg-transparent;
    }

    input[type='range']:focus {
        @apply outline-none;
    }

    input[type='range']::-webkit-slider-runnable-track {
        @apply appearance-none w-full h-4 cursor-pointer bg-gray-600 border-0 shadow-none rounded-full;
        /* animate: 0.2s; */
    }

    input[type='range']::-webkit-slider-thumb {
        @apply appearance-none relative -mt-3 w-10 h-10 bg-gray-900 cursor-pointer rounded-full shadow-lg z-50;
    }
</style>
