async function asyncMap(data, callback) {
	return await Promise.all(data.map(item => callback(item)));
};

export default async function(data, inputKey, outputKey, replacer) {
	return await asyncMap(data, async item => {
		const { data } = await replacer(item[inputKey]);

		return {
			...item,
			[outputKey]: data,
		};
	});
};
